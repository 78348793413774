aside.emoji-picker-react {
  box-shadow: 0 5px 10px transparent !important;
}
.vjs-error-display, .vjs-modal-dialog{
  display: none;
}
.vjs-mux {
  width: auto !important;
  display: flex;
  height: 100% !important;
  position: relative;
  width: 100% !important;
  align-items: center !important;
  justify-content: center !important;
  position: relative !important;
  overflow: hidden;
}
.vjs-mux video {
  width: 100% !important;
  height: auto !important;
  max-width: 100% !important;
}
@media screen and (max-aspect-ratio: 12/16) and (min-aspect-ratio: 6/16) {
  .vjs-mux video {
    /* width: auto !important;
    height: 100% !important;
    max-width: unset !important; */
    
    position: absolute;
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media screen and (min-aspect-ratio: 14/16) {
  .vjs-mux video {
    max-height: 100% !important;
  }
}
.vjs-control-text {
  display: none !important;
}
button:focus {
  box-shadow: none !important;
}

.video-player-bg {
  background: red;
}